<template>
  <v-app-bar height="3vh" app>
    <v-row style="display: flex; align-items: center; justify-content: center;">
      <v-col cols="2">
        <v-img :src="require('../assets/logoCoquelicot.png')" max-height="100px"></v-img>
        <v-text-h1>$iutinfo</v-text-h1>
      </v-col>
      <v-col cols="8">
        <chokbardebezed :value="score"></chokbardebezed>
      </v-col>
      <v-col cols="2">
        <v-btn value="clair" @click="toggleTheme"><v-icon right>mdi-theme-light-dark</v-icon></v-btn> 
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Chokbardebezed from "@/components/chokbardebezed.vue";
export default {
  name: "barre-navigation",
  components: {Chokbardebezed},
  computed: {
    ...mapGetters({
        score: "score/getScore"
    })
  },

};
</script>

<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

function toggleTheme () {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
}
</script>

<style scoped>

</style>