export default {
    namespaced: true,
    state: {
        categories: []
    },
    getters: {
        getCategories(state) {
            return state.categories;
        },
    },
}