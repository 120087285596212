<template>
  <v-app>
    <v-main>
      <barre-navigation></barre-navigation>
      <v-container class="pa-0 ma-0" fluid="">
        <v-img cover="" :src="getBackground">
          <router-view></router-view>
        </v-img>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
.background-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-attachment: fixed;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script setup>
import BarreNavigation from "@/components/barre-navigation.vue";
</script>
<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      score: "score/getScore"
    }),
    getBackground() {
      if(this.score === 0) {
        return require("@/assets/foutrement_a_chier.jpg");
      } else if (this.score < 20) {
        return require("@/assets/mauvais.jpg");
      } else if (this.score < 40) {
        return require("@/assets/pas_bien.jpg");
      } else if (this.score < 60) {
        return require("@/assets/neutre.jpg");
      } else if (this.score < 80) {
        return require("@/assets/bien.jpg");
      } else if (this.score < 100) {
        return require("@/assets/oh_putain.jpg");
      } else {
        return require("@/assets/je_bande.jpg");
      }
    }
  }
}
</script>