<template>
  <v-container>
    <v-row class="pa-6" justify="space-between">
      <v-icon size="x-large">mdi-tree</v-icon>
      <v-icon size="x-large">mdi-factory</v-icon>
    </v-row>
    <div class="slider-container">
      <sliderBien :value="value"></sliderBien>
      <sliderMal class="sliderMal" :value="100-value"></sliderMal>
    </div>
  </v-container>
</template>

<script>

import sliderBien from '@/components/sliderBien.vue'
import sliderMal from '@/components/sliderMal.vue'


export default {
  name: 'chokbardebezed',
  components: {
    sliderBien,
    sliderMal
  },
  props:{
    value:{
      type: Number,
      default:50
    }
  }
}

</script>

<style>
.slider-container{
  position: relative;
}
.sliderMal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>