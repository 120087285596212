export default {
    namespaced: true,
    state: {
        question: [
          {
            "categorie": "Biodiversité",
            "image": require("@/assets/vaches-usines.png"),
            "titre": "Vous matchez avec un koala vous ?",
            "texte": "Les espèces en voie de disparition ne concernent que des animaux exotiques, pas ceux présents dans nos régions.",
            "est_Vrai": false,
            "couleurCarte": "#7EBCAB",
            "couleurtexte": "#A1CEBD",
            "icon": "mdi-leaf",
            "lien": "https://www.energy-observer.org/fr/ressources/pollution-plastique-biodiversite-marine#:~:text=Plus%20de%20800%20espèces%20animales,overdose%20de%20plastique%20chaque%20année"
          },
          {
            "categorie": "Eco-industrie",
            "image": require("@/assets/greenwashing.jpg"),
            "titre": "Une relation avec quelqu'un de votre job ?",
            "texte": "Les entreprises adoptant des pratiques écologiques le font principalement pour des raisons de marketing plutôt que par réelle conviction environnementale.",
            "est_Vrai": true,
            "couleurCarte": "#FECB9D",
            "couleurtexte": "#FFD8B6",
            "icon": "mdi-factory",
            "lien": "https://www.riav.fr/politique-environnementale-dentreprise-ou-en-est-on/#:~:text=Exemple%20de%20politique%20environnementale%20d,il%20est%20recyclable%20ou%20réutilisable."
          },
          {
            "categorie": "Education",
            "image": require("@/assets/Education-environementale.png"),
            "titre": "Vous recroisez quelqu'un de votre école primaire",
            "texte": "L'éducation environnementale ne joue qu'un rôle mineur dans la protection de la planète.",
            "est_Vrai": false,
            "couleurCarte": "#A5D1E6",
            "couleurtexte": "#C4E8F6",
            "icon": "mdi-book-open-blank-variant",
            "lien": "https://www.education.gouv.fr/l-education-au-developpement-durable-7136 \n https://www.cairn.info/revue-cereq-bref-2022-1-page-1.htm \n https://www.sudouest.fr/politique/education/education-il-faut-sensibiliser-davantage-les-eleves-a-l-environnement-4404385.php"
          },{
            "categorie": "Education",
            "image": require("@/assets/education.png"),
            "titre": "Vous recroisez quelqu'un de votre école primaire",
            "texte": "Les programmes d'éducation environnementale sont essentiels pour susciter un changement positif de comportement.",
            "est_Vrai": true,
            "couleurCarte": "#A5D1E6",
            "couleurtexte": "#C4E8F6",
            "icon": "mdi-book-open-blank-variant",
            "lien": "https://www.education.gouv.fr/l-education-au-developpement-durable-7136 \n https://www.cairn.info/revue-cereq-bref-2022-1-page-1.htm \n https://www.sudouest.fr/politique/education/education-il-faut-sensibiliser-davantage-les-eleves-a-l-environnement-4404385.php"
          },{
            "categorie": "Nature",
            "image": require("@/assets/produit-de-saison.webp"),
            "titre": "Une relation à distance ?",
            "texte": "Choisir des produits de saison ne fait pas de différence dans la réduction de l'empreinte écologique.",
            "est_Vrai": true,
            "couleurCarte": "#C194BA",
            "couleurtexte": "#E1BBCF",
            "icon": "mdi-pine-tree-variant",
            "lien": "https://www.bioalaune.com/fr/actualite-bio/5293/6e-continent-nouvelle-terre-de-dechets"
          },
          {
            "categorie": "consommation responsable",
            "image": require("@/assets/legumeBio.png"),
            "titre": "Une relation avec quelqu'un de plus riche que vous ?",
            "texte": "La consommation responsable est un luxe inaccessible pour la plupart des gens.",
            "est_Vrai": false,
            "couleurCarte": "#FFF791",
            "couleurtexte": "#FFF9B3",
            "icon": "mdi-basket"
          },
          {
            "categorie": "Eco-industrie",
            "image": require("@/assets/industrie-ecologique.jpg"),
            "titre": "Une relation avec quelqu'un avec des convictions différentes ?",
            "texte": "L'industrie écologique est une tendance éphémère sans réelle influence sur l'environnement.",
            "est_Vrai": false,
            "couleurCarte": "#FECB9D",
            "couleurtexte": "#FFD8B6",
            "icon": "mdi-factory",
            "lien": "https://www.riav.fr/politique-environnementale-dentreprise-ou-en-est-on/#:~:text=Exemple%20de%20politique%20environnementale%20d,il%20est%20recyclable%20ou%20réutilisable."
          },
          {
            "categorie": "Politique",
            "image": require("@/assets/politiques.jpeg"),
            "titre": "Une relation avec quelqu'un avec des convictions différentes ?",
            "texte": "Des politiques gouvernementales solides sont essentielles pour encourager des pratiques industrielles plus durables.",
            "est_Vrai": true,
            "couleurCarte": "#FF9688",
            "couleurtexte": "#FFBFB0",
            "icon": "mdi-human-male-board-poll",
            "lien": "https://www.melchior.fr/cours/complet/question-3-quelles-sont-les-limites-et-les-contraintes-de-l-action-publique-pour-l"
          },{
            "categorie": "consommation responsable",
            "image": require("@/assets/consommation-plastiques.webp"),
            "titre": "Une relation avec quelqu'un avec des convictions différentes ?",
            "texte": "La réduction de la consommation de plastique est une solution efficace pour lutter contre la pollution.",
            "est_Vrai": true,
            "couleurCarte": "#FFF791",
            "couleurtexte": "#FFF9B3",
            "icon": "mdi-basket"
          }

        ]
    },
    getters: {
        getQuestions(state) {
            return state.question;
        },
    },
}