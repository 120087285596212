<template>
    <div>
        <v-slider
        color="#e64553"
        track-color="transparent"
        track-size="30"
        thumb-size="40"
        thumb-color="#dc8a78"
        :model-value="value"
        reverse
        readonly
        >
        </v-slider>
    </div>
</template>

<script>
export default {
    props:{
    value:{
      type: Number,
      default:50
    }
  }
}
</script>