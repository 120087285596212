import { createStore } from 'vuex'
import question from "@/store/modules/question";
import score from "@/store/modules/score";
import categories from "@/store/modules/categories";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    question,
    score,
    categories
  }
})
