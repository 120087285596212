export default {
    namespaced: true,
    state: {
        score: 50
    },
    getters: {
        getScore(state) {
            return state.score;
        },

    },
    mutations:{
        ajouterScore(state, value){
            state.score = state.score+value
        },
        retirerScore(state, value){
            state.score = state.score-value
        }
    },
    actions:{
        ajouterScore(context, n){
            context.commit('ajouterScore', n)
        },
        retirerScore(context, n){
            context.commit('retirerScore', n)
        }
    }
}